/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createSystemSettingInfo = /* GraphQL */ `
  mutation CreateSystemSettingInfo(
    $input: CreateSystemSettingInfoInput!
    $condition: ModelSystemSettingInfoConditionInput
  ) {
    createSystemSettingInfo(input: $input, condition: $condition) {
      id
      systemSettingContents
      autoPostSettingFlg
      ownerCompanyId
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const updateSystemSettingInfo = /* GraphQL */ `
  mutation UpdateSystemSettingInfo(
    $input: UpdateSystemSettingInfoInput!
    $condition: ModelSystemSettingInfoConditionInput
  ) {
    updateSystemSettingInfo(input: $input, condition: $condition) {
      id
      systemSettingContents
      autoPostSettingFlg
      ownerCompanyId
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const deleteSystemSettingInfo = /* GraphQL */ `
  mutation DeleteSystemSettingInfo(
    $input: DeleteSystemSettingInfoInput!
    $condition: ModelSystemSettingInfoConditionInput
  ) {
    deleteSystemSettingInfo(input: $input, condition: $condition) {
      id
      systemSettingContents
      autoPostSettingFlg
      ownerCompanyId
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const createImageInfo = /* GraphQL */ `
  mutation CreateImageInfo(
    $input: CreateImageInfoInput!
    $condition: ModelImageInfoConditionInput
  ) {
    createImageInfo(input: $input, condition: $condition) {
      id
      uploadDate
      imageTitle
      imageFileNm
      generatePostId
      generatePost {
        id
        generatePostDate
        generatePostContents
        autoPostFlg
        systemSettingContents
        systemSettingId
        postInstagramId
        ownerCompanyId
        createdAt
        updatedAt
        owner
        __typename
      }
      ownerCompanyId
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const updateImageInfo = /* GraphQL */ `
  mutation UpdateImageInfo(
    $input: UpdateImageInfoInput!
    $condition: ModelImageInfoConditionInput
  ) {
    updateImageInfo(input: $input, condition: $condition) {
      id
      uploadDate
      imageTitle
      imageFileNm
      generatePostId
      generatePost {
        id
        generatePostDate
        generatePostContents
        autoPostFlg
        systemSettingContents
        systemSettingId
        postInstagramId
        ownerCompanyId
        createdAt
        updatedAt
        owner
        __typename
      }
      ownerCompanyId
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const deleteImageInfo = /* GraphQL */ `
  mutation DeleteImageInfo(
    $input: DeleteImageInfoInput!
    $condition: ModelImageInfoConditionInput
  ) {
    deleteImageInfo(input: $input, condition: $condition) {
      id
      uploadDate
      imageTitle
      imageFileNm
      generatePostId
      generatePost {
        id
        generatePostDate
        generatePostContents
        autoPostFlg
        systemSettingContents
        systemSettingId
        postInstagramId
        ownerCompanyId
        createdAt
        updatedAt
        owner
        __typename
      }
      ownerCompanyId
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const createGeneratePostInfo = /* GraphQL */ `
  mutation CreateGeneratePostInfo(
    $input: CreateGeneratePostInfoInput!
    $condition: ModelGeneratePostInfoConditionInput
  ) {
    createGeneratePostInfo(input: $input, condition: $condition) {
      id
      generatePostDate
      generatePostContents
      autoPostFlg
      systemSettingContents
      systemSettingId
      systemSetting {
        id
        systemSettingContents
        autoPostSettingFlg
        ownerCompanyId
        createdAt
        updatedAt
        owner
        __typename
      }
      postInstagramId
      postInstagram {
        id
        postInstagramDate
        postInstagramContents
        exampleAnswerFlg
        postInstagramMedia
        generatePostId
        ownerCompanyId
        createdAt
        updatedAt
        owner
        __typename
      }
      imageInfo {
        nextToken
        __typename
      }
      ownerCompanyId
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const updateGeneratePostInfo = /* GraphQL */ `
  mutation UpdateGeneratePostInfo(
    $input: UpdateGeneratePostInfoInput!
    $condition: ModelGeneratePostInfoConditionInput
  ) {
    updateGeneratePostInfo(input: $input, condition: $condition) {
      id
      generatePostDate
      generatePostContents
      autoPostFlg
      systemSettingContents
      systemSettingId
      systemSetting {
        id
        systemSettingContents
        autoPostSettingFlg
        ownerCompanyId
        createdAt
        updatedAt
        owner
        __typename
      }
      postInstagramId
      postInstagram {
        id
        postInstagramDate
        postInstagramContents
        exampleAnswerFlg
        postInstagramMedia
        generatePostId
        ownerCompanyId
        createdAt
        updatedAt
        owner
        __typename
      }
      imageInfo {
        nextToken
        __typename
      }
      ownerCompanyId
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const deleteGeneratePostInfo = /* GraphQL */ `
  mutation DeleteGeneratePostInfo(
    $input: DeleteGeneratePostInfoInput!
    $condition: ModelGeneratePostInfoConditionInput
  ) {
    deleteGeneratePostInfo(input: $input, condition: $condition) {
      id
      generatePostDate
      generatePostContents
      autoPostFlg
      systemSettingContents
      systemSettingId
      systemSetting {
        id
        systemSettingContents
        autoPostSettingFlg
        ownerCompanyId
        createdAt
        updatedAt
        owner
        __typename
      }
      postInstagramId
      postInstagram {
        id
        postInstagramDate
        postInstagramContents
        exampleAnswerFlg
        postInstagramMedia
        generatePostId
        ownerCompanyId
        createdAt
        updatedAt
        owner
        __typename
      }
      imageInfo {
        nextToken
        __typename
      }
      ownerCompanyId
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const createPostInstagramInfo = /* GraphQL */ `
  mutation CreatePostInstagramInfo(
    $input: CreatePostInstagramInfoInput!
    $condition: ModelPostInstagramInfoConditionInput
  ) {
    createPostInstagramInfo(input: $input, condition: $condition) {
      id
      postInstagramDate
      postInstagramContents
      exampleAnswerFlg
      postInstagramMedia
      generatePostId
      generatePost {
        id
        generatePostDate
        generatePostContents
        autoPostFlg
        systemSettingContents
        systemSettingId
        postInstagramId
        ownerCompanyId
        createdAt
        updatedAt
        owner
        __typename
      }
      ownerCompanyId
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const updatePostInstagramInfo = /* GraphQL */ `
  mutation UpdatePostInstagramInfo(
    $input: UpdatePostInstagramInfoInput!
    $condition: ModelPostInstagramInfoConditionInput
  ) {
    updatePostInstagramInfo(input: $input, condition: $condition) {
      id
      postInstagramDate
      postInstagramContents
      exampleAnswerFlg
      postInstagramMedia
      generatePostId
      generatePost {
        id
        generatePostDate
        generatePostContents
        autoPostFlg
        systemSettingContents
        systemSettingId
        postInstagramId
        ownerCompanyId
        createdAt
        updatedAt
        owner
        __typename
      }
      ownerCompanyId
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const deletePostInstagramInfo = /* GraphQL */ `
  mutation DeletePostInstagramInfo(
    $input: DeletePostInstagramInfoInput!
    $condition: ModelPostInstagramInfoConditionInput
  ) {
    deletePostInstagramInfo(input: $input, condition: $condition) {
      id
      postInstagramDate
      postInstagramContents
      exampleAnswerFlg
      postInstagramMedia
      generatePostId
      generatePost {
        id
        generatePostDate
        generatePostContents
        autoPostFlg
        systemSettingContents
        systemSettingId
        postInstagramId
        ownerCompanyId
        createdAt
        updatedAt
        owner
        __typename
      }
      ownerCompanyId
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const createChatInfo = /* GraphQL */ `
  mutation CreateChatInfo(
    $input: CreateChatInfoInput!
    $condition: ModelChatInfoConditionInput
  ) {
    createChatInfo(input: $input, condition: $condition) {
      id
      chatDate
      chatQuestion
      chatAnswer
      ragId
      rag {
        id
        ragContents
        datasourceFileNm
        reflectFlg
        chatId
        ownerCompanyId
        createdAt
        updatedAt
        owner
        __typename
      }
      ownerCompanyId
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const updateChatInfo = /* GraphQL */ `
  mutation UpdateChatInfo(
    $input: UpdateChatInfoInput!
    $condition: ModelChatInfoConditionInput
  ) {
    updateChatInfo(input: $input, condition: $condition) {
      id
      chatDate
      chatQuestion
      chatAnswer
      ragId
      rag {
        id
        ragContents
        datasourceFileNm
        reflectFlg
        chatId
        ownerCompanyId
        createdAt
        updatedAt
        owner
        __typename
      }
      ownerCompanyId
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const deleteChatInfo = /* GraphQL */ `
  mutation DeleteChatInfo(
    $input: DeleteChatInfoInput!
    $condition: ModelChatInfoConditionInput
  ) {
    deleteChatInfo(input: $input, condition: $condition) {
      id
      chatDate
      chatQuestion
      chatAnswer
      ragId
      rag {
        id
        ragContents
        datasourceFileNm
        reflectFlg
        chatId
        ownerCompanyId
        createdAt
        updatedAt
        owner
        __typename
      }
      ownerCompanyId
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const createRagInfo = /* GraphQL */ `
  mutation CreateRagInfo(
    $input: CreateRagInfoInput!
    $condition: ModelRagInfoConditionInput
  ) {
    createRagInfo(input: $input, condition: $condition) {
      id
      ragContents
      datasourceFileNm
      reflectFlg
      chatId
      chat {
        id
        chatDate
        chatQuestion
        chatAnswer
        ragId
        ownerCompanyId
        createdAt
        updatedAt
        owner
        __typename
      }
      ownerCompanyId
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const updateRagInfo = /* GraphQL */ `
  mutation UpdateRagInfo(
    $input: UpdateRagInfoInput!
    $condition: ModelRagInfoConditionInput
  ) {
    updateRagInfo(input: $input, condition: $condition) {
      id
      ragContents
      datasourceFileNm
      reflectFlg
      chatId
      chat {
        id
        chatDate
        chatQuestion
        chatAnswer
        ragId
        ownerCompanyId
        createdAt
        updatedAt
        owner
        __typename
      }
      ownerCompanyId
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const deleteRagInfo = /* GraphQL */ `
  mutation DeleteRagInfo(
    $input: DeleteRagInfoInput!
    $condition: ModelRagInfoConditionInput
  ) {
    deleteRagInfo(input: $input, condition: $condition) {
      id
      ragContents
      datasourceFileNm
      reflectFlg
      chatId
      chat {
        id
        chatDate
        chatQuestion
        chatAnswer
        ragId
        ownerCompanyId
        createdAt
        updatedAt
        owner
        __typename
      }
      ownerCompanyId
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
