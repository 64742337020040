import * as React from 'react';
import {useRecoilValue} from 'recoil';
import Button from '@mui/joy/Button';
import FormControl from '@mui/joy/FormControl';
import Textarea from '@mui/joy/Textarea';
import Modal from '@mui/joy/Modal';
import ModalClose from '@mui/joy/ModalClose';
import ModalDialog from '@mui/joy/ModalDialog';
import DialogTitle from '@mui/joy/DialogTitle';
import DialogContent from '@mui/joy/DialogContent';
import Stack from '@mui/joy/Stack';
import * as recoil from 'components/recoil';

// Amplify API
import {generateClient} from 'aws-amplify/api';
import {createRagInfo, updateRagInfo, updateChatInfo} from 'graphql/mutations';
import {getRagInfo} from 'graphql/queries';
const apiClient = generateClient();

export default function BasicModalDialog({open, setOpen, selectedRow, getChatList}) {
	const usergroup = useRecoilValue(recoil.usergroup);
	const [rag, setRag] = React.useState({ragContents: ''});
	// 初期設定
	React.useEffect(() => {
		const init = async () => {
			console.log(selectedRow);
			if(selectedRow.ragId) {
				const result = await apiClient.graphql({
					query: getRagInfo,
					variables: {id: selectedRow.ragId}
				});
				setRag(result.data.getRagInfo);
			} else {
				// RAG登録内容の初期表示
				setRag({ragContents: 
`【質問】
${selectedRow.chatQuestion}

【回答】
${selectedRow.chatAnswer}`
				});
			}
		};
		init();
	}, [selectedRow]);

	// RAG登録ボタン押下
	const clickPost = async event => {
		event.preventDefault();
		try {
			updateDynamoDB();
			getChatList();
			setOpen(false);
		} catch(err) {
			console.log(err);
		}
	};
	
	// DynamoDB 更新
	const updateDynamoDB = async () => {
		if(rag.id) {
			// RAG登録情報 更新
			await apiClient.graphql({
				query: updateRagInfo,
				variables: {input: {
					id: rag.id,
					reflectFlg: 'OFF',
					ragContents: rag.ragContents,
				}}
			});
		} else {
			// RAG登録情報 登録
			const result = await apiClient.graphql({
				query: createRagInfo,
				variables: {input: {
					ragContents: rag.ragContents,
					reflectFlg: 'OFF',
					chatId: selectedRow.id,
					ownerCompanyId: usergroup
				}}
			});
			const ragId = result.data.createRagInfo.id;
			// チャット情報 更新
			apiClient.graphql({
				query: updateChatInfo,
				variables: {input: {
					id: selectedRow.id,
					ragId: ragId
				}}
			});
			setRag(prev => ({...prev, id: ragId}));
		}
	}
	
	// 画面描画
	return (
		<Modal open={open} onClose={() => setOpen(false)}>
			<ModalDialog sx={{width: '80%'}}>
				<ModalClose />
				<DialogTitle>データソース登録</DialogTitle>
				<DialogContent>データソースに登録したい内容を記載してください</DialogContent>
				<Stack spacing={2}>
					<FormControl>
						<Textarea
							value={rag.ragContents}
							onChange={event => setRag(prev => ({...prev, ragContents: event.target.value}))}
							minRows={4} sx={{mt: 1.5}}
						/>
					</FormControl>
					{!rag.ragMedia &&
						<>
							<Button variant='solid' onClick={event => clickPost(event)}>RAG登録</Button>
						</>
					}
				</Stack>
			</ModalDialog>
		</Modal>
	);
}