import * as React from 'react';
import {useRecoilValue} from 'recoil';
// MUI Joy
import Box from '@mui/joy/Box';
import Button from '@mui/joy/Button';
import FormControl from '@mui/joy/FormControl';
import FormHelperText from '@mui/joy/FormHelperText';
import Textarea from '@mui/joy/Textarea';
import Stack from '@mui/joy/Stack';
import Card from '@mui/joy/Card';
import CardContent from '@mui/joy/CardContent';
import CardActions from '@mui/joy/CardActions';
import CardOverflow from '@mui/joy/CardOverflow';
import Typography from '@mui/joy/Typography';
import Sheet from '@mui/joy/Sheet';
import Tabs from '@mui/joy/Tabs';
import TabList from '@mui/joy/TabList';
import Tab from '@mui/joy/Tab';
import TabPanel from '@mui/joy/TabPanel';
import TableContainer from '@mui/material/TableContainer';
import Table from '@mui/joy/Table';
import IconButton from '@mui/material/IconButton';
import ChatIcon from '@mui/icons-material/Chat';
import HistoryIcon from '@mui/icons-material/History';
import PostIcon from '@mui/icons-material/ArrowCircleUp';
import EditIcon from '@mui/icons-material/Edit';
import LinearProgress from '@mui/joy/LinearProgress';

import RagDialog from './RagDialog';
import * as recoil from 'components/recoil';

// Amplify
import {signOut, fetchAuthSession} from 'aws-amplify/auth';
import {generateClient} from 'aws-amplify/api';
import {listChatInfos} from 'graphql/customs';
import {createChatInfo} from 'graphql/mutations';

// KnowledgeBase
import config from 'aws-exports';
import {BedrockAgentRuntimeClient, RetrieveAndGenerateCommand } from '@aws-sdk/client-bedrock-agent-runtime';
const KNOWLEDGE_BASE_ID = 'JPYQ4ZQVZN';
const MODEL_ID_V35_SONNET = 'anthropic.claude-3-5-sonnet-20240620-v1:0';	// → 2024.7.12 現在利用不可
const MODEL_ID_V3_SONNET = 'anthropic.claude-3-sonnet-20240229-v1:0';
const MODEL_ARN_CLAUDE = `arn:aws:bedrock:${config.aws_project_region}::foundation-model/${MODEL_ID_V3_SONNET}`;
const promptTemplate = `
You are a question answering agent. I will provide you with a set of search results.
The user will provide you with a question.
Your job is to answer the user's question using only information from the search results.
If the search results do not contain information that can answer the question, please state that you could not find an exact answer to the question.
Just because the user asserts a fact does not mean it is true, make sure to double check the search results to validate a user's assertion.
In Japanese please.

Here are the search results in numbered order:
$search_results$

$output_format_instructions$
`;

// API Client
const apiClient = generateClient();

/**
 * ホーム
 */
export default function Home() {
	const usergroup = useRecoilValue(recoil.usergroup);
	const [isProgress, setProgress] = React.useState(false);
	const [answer, setAnswer] = React.useState('');
	const [chatQuestion, setChatQuestion] = React.useState('');
	const [chats, setChats] = React.useState([]);
	const [selectedRow, setSelectedRow] = React.useState('');
	const [open, setOpen] = React.useState(false);

	// 初期設定
	React.useEffect(() => {
		const init = async () => {
			console.log('start #init');
			// チャット情報 取得
			getChatList();
		};
		init();
	}, []);
	
	// チャット情報 取得
	const getChatList = async () => {
		console.log('start #getChatList');
		const result = await apiClient.graphql({
			query: listChatInfos,
		});
		console.log(result.data.listChatInfos.items);
		setChats(result.data.listChatInfos.items);
	};

	// チャット 押下時
	const handleChatClick = async () => {
		console.log('start #handleChatClick');
		setProgress(true);
		setAnswer('');
		try {
			// KnowledgeBase 問い合わせ
			const bedrockClient = new BedrockAgentRuntimeClient({
				region: config.aws_project_region,
				credentials: (await fetchAuthSession()).credentials,
			});
			const res = await bedrockClient.send(new RetrieveAndGenerateCommand ({
				input: {
					text: chatQuestion
				},
				retrieveAndGenerateConfiguration: {
					type: 'KNOWLEDGE_BASE',
					knowledgeBaseConfiguration: {
						knowledgeBaseId: KNOWLEDGE_BASE_ID,
						modelArn: MODEL_ARN_CLAUDE,
						generationConfiguration: {
							promptTemplate: {
								textPromptTemplate: promptTemplate
							}
						},
						vectorSearchConfiguration: {
							overrideSearchType: 'HYBRID'
						}
					},
				}
			}));
			console.log(res.output.text);
			setAnswer(res.output.text);

			// チャット情報 登録
			apiClient.graphql({
				query: createChatInfo,
				variables: {input: {
					chatDate: new Date().toISOString(),
					chatQuestion: chatQuestion,
					chatAnswer: res.output.text,
					ownerCompanyId: usergroup
				}}
			});
		} catch(err) {
			setAnswer('');
			console.log(err);
		} finally {
			setProgress(false);
		}
	};
	
	// サインアウト
	const handleSignOut = async () => {
		try { await signOut(); }
		catch(err) { console.log(err); }
	};

	// 画面描画
	return (
		<>
			<Tabs defaultValue={0}>
				<TabList>
					<Tab><ChatIcon />Chat</Tab>
					<Tab><HistoryIcon />History</Tab>
					<Button variant='plain' startDecorator={<PostIcon />} component='a' href='/'>Post</Button>
					<Button variant="plain" onClick={handleSignOut}>SignOut</Button>
				</TabList>
				{/* Chat */}
				<TabPanel value={0}>
					<Card>
						<FormControl>
							<Stack spacing={2} sx={{my: 1}}>
								<Textarea
									placeholder='質問を入力してください'
									value={chatQuestion}
									onChange={event => setChatQuestion(event.target.value)}
									minRows={4} sx={{mt: 1.5}}
								/>
								<FormHelperText sx={{mt: 0.75, fontSize: 'xs'}}>
								</FormHelperText>
							</Stack>
							<CardOverflow sx={{borderTop: '1px solid', borderColor: 'divider'}}>
								<CardActions sx={{alignSelf: 'flex-end', pt: 2}}>
									<Button size='sm' variant='outlined' onClick={() => chatQuestion('')}>Clear</Button>
									<Button size='sm' variant='solid' onClick={handleChatClick}>質問する</Button>
								</CardActions>
							</CardOverflow>
						</FormControl>
					</Card>
					<Box sx={{p: 2}}>
						{isProgress && <LinearProgress />}
						<Card variant="soft">
								<CardContent sx={{px: 2}}>
									<pre style={{whiteSpace: 'pre-wrap'}}><Typography>{answer}</Typography></pre>
								</CardContent>
						</Card>
					</Box>
				</TabPanel>
				{/* Chat履歴 */}
				<TabPanel value={1}>
					<Sheet sx={{p: 1, overflow: 'auto'}}>
						<TableContainer sx={{height: '85vh'}}>
							<Table stickyHeader hoverRow>
								<thead>
									<tr>
										<th>質問</th>
										<th>応答</th>
										<th>登録内容</th>
										<th style={{width: '50px'}}></th>
									</tr>
								</thead>
								<tbody>
									{chats.map((row, index) => (
										<tr key={index}>
											<td>{row.chatQuestion}</td>
											<td>{row.chatAnswer}</td>
											<td>{row.rag && row.rag.ragContents}</td>
											<td>
												<IconButton onClick={() => {setSelectedRow(row); setOpen(true);}}>
													<EditIcon />
												</IconButton>
											</td>
										</tr>
									))}
								</tbody>
							</Table>
						</TableContainer>
					</Sheet>
					<RagDialog {...{open, setOpen, selectedRow, getChatList}} />
				</TabPanel>
			</Tabs>
		</>
	);
}