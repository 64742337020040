import * as React from 'react';
import {useRecoilValue} from 'recoil';
import Dropzone from 'react-dropzone';
// MUI Joy
import Box from '@mui/joy/Box';
import Button from '@mui/joy/Button';
import FormControl from '@mui/joy/FormControl';
import FormHelperText from '@mui/joy/FormHelperText';
import Textarea from '@mui/joy/Textarea';
import Input from '@mui/joy/Input';
import Switch from '@mui/joy/Switch';
import Stack from '@mui/joy/Stack';
import Typography from '@mui/joy/Typography';
import Card from '@mui/joy/Card';
import CardActions from '@mui/joy/CardActions';
import CardOverflow from '@mui/joy/CardOverflow';
import Sheet from '@mui/joy/Sheet';
import Tabs from '@mui/joy/Tabs';
import TabList from '@mui/joy/TabList';
import Tab from '@mui/joy/Tab';
import TabPanel from '@mui/joy/TabPanel';
import TableContainer from '@mui/material/TableContainer';
import Table from '@mui/joy/Table';
import IconButton from '@mui/material/IconButton';
import Progress from '@mui/material/LinearProgress';
import UploadIcon from '@mui/icons-material/CloudUploadOutlined';
import PostIcon from '@mui/icons-material/ArrowCircleUp';
import ListIcon from '@mui/icons-material/List';
import ChatIcon from '@mui/icons-material/Chat';
import SettingsIcon from '@mui/icons-material/Settings';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';

import BasicModalDialog from './BasicModalDialog';
import * as recoil from 'components/recoil';

// Amplify
import {StorageImage} from '@aws-amplify/ui-react-storage';
import {signOut} from 'aws-amplify/auth';
import {uploadData} from 'aws-amplify/storage';
import {generateClient} from 'aws-amplify/api';
import {generate, listSystemSettingInfos} from 'graphql/queries';
import {listImageInfos} from 'graphql/customs';
import {
	createSystemSettingInfo, updateSystemSettingInfo,
	createImageInfo, deleteImageInfo, deleteGeneratePostInfo,
	updatePostInstagramInfo
} from 'graphql/mutations';
const client = generateClient();

/**
 * ホーム
 */
export default function Home() {
	const usergroup = useRecoilValue(recoil.usergroup);
	const systemSetting = React.useRef({});
	const [selectedRow, setSelectedRow] = React.useState('');
	const [systemSettingContents, setSystemSettingContents] = React.useState('');
	const [imageTitle, setImageTitle] = React.useState('');
	const [images, setImages] = React.useState([]);
	const [files, setFiles] = React.useState([]);
	const [open, setOpen] = React.useState(false);
	
	// 初期設定
	React.useEffect(() => {
		const init = async () => {
			console.log('start #init');
			// システム設定情報 取得
			const result = await client.graphql({
				query: listSystemSettingInfos,
			});
			if(result.data.listSystemSettingInfos.items.length > 0) {
				systemSetting.current = result.data.listSystemSettingInfos.items[0];
				setSystemSettingContents(systemSetting.current.systemSettingContents);
			}
			// 画像情報 取得
			getImageList();
		};
		init();
	}, []);
	
	// システム設定 保存押下時
	const handleSystemClick = async () => {
		console.log('start #handleSystemClick');
		try {
			await client.graphql({
				query: systemSetting.current.id ? updateSystemSettingInfo : createSystemSettingInfo,
				variables: {input: {
					id: systemSetting.current.id,
					systemSettingContents: systemSettingContents,
					autoPostSettingFlg: 'OFF',
					ownerCompanyId: usergroup
				}}
			});
		} catch(err) {
			console.log(err);
		}
	};
	
	// 画像情報 取得
	const getImageList = async () => {
		console.log('start #getImageList');
		const result = await client.graphql({
			query: listImageInfos,
		});
		setImages(result.data.listImageInfos.items);
	};

	// 画像投稿 Save押下時
	const handleImageClick = async () => {
		console.log('start #handleImageClick');
		try {
			if (imageTitle.trim() !== '') {
				// 画像情報 登録
				const result = await client.graphql({
					query: createImageInfo,
					variables: {input: {
						uploadDate: new Date().toISOString(),
						imageTitle: imageTitle,
						imageFileNm: files[0].name,
						ownerCompanyId: usergroup
					}}
				});
				// 投稿生成
				client.graphql({
					query: generate,
					variables: {imageId: result.data.createImageInfo.id}
				}).then(ret => getImageList());
				setImageTitle('');
				setFiles([]);
			}
		} catch(err) {
			console.log(err);
		}
	};
	// ファイルドロップ
	const dropFiles = async (acceptedFiles) => {
		console.log('start #dropFiles');
		acceptedFiles.forEach(async (file, i) => {
			setFiles([{name: file.name, progress: 0}]);
			try {
				uploadData({
					key: file.name,
					data: file,
					options: {
						onProgress: ({transferredBytes, totalBytes}) => {
							if(totalBytes) {
								const p = Math.round((transferredBytes / totalBytes) * 100);
								setFiles([{name: file.name, progress: p}]);
							}
						}
					}
				});
			} catch (error) {
				console.log(error);
			} finally {
				setImageTitle('');
				setFiles([]);
			}
		});
	};
	// 行削除
	const deleteRow = async (event, row) => {
		console.log('start #deleteImage');
		if (window.confirm('本当に削除しますか？')) {
			try {
				// 画像情報 削除
				await client.graphql({
					query: deleteImageInfo,
					variables: {input: {
						id: row.id
					}}
				});
				// 投稿生成情報 削除
				await client.graphql({
					query: deleteGeneratePostInfo,
					variables: {input: {
						id: row.generatePostId
					}}
				});
				
				getImageList();
			} catch(err) {
				console.log(err);
			}
		}
	};
	// 回答例 チェック
	const checkExampleAnswer = async (event, row) => {
		console.log('start #checkExampleAnswer');
		const postInstagram = row.generatePost.postInstagram;
		if(postInstagram) {
			try {
				// インスタ投稿情報 更新
				await client.graphql({
					query: updatePostInstagramInfo,
					variables: {input: {
						id: postInstagram.id,
						exampleAnswerFlg: event.target.checked ? 'ON' : 'OFF',
					}}
				});
				getImageList();
			} catch(err) {
				console.log(err);
			}
		}
	};
	// サインアウト
	const handleSignOut = async () => {
		try { await signOut(); }
		catch(err) { console.log(err); }
	};


	// 画面描画
	return (
		<>
			<Tabs defaultValue={0}>
				<TabList>
					<Tab><PostIcon />Post</Tab>
					<Tab><ListIcon />List</Tab>
					<Tab><SettingsIcon />Setting</Tab>
					<Button variant='plain' startDecorator={<ChatIcon />} component='a' href='/chat'>Chat</Button>
					<Button variant='plain' onClick={handleSignOut}>SignOut</Button>
				</TabList>
				{/* 画像投稿 */}
				<TabPanel value={0}>
					<Card>
						<FormControl>
							<Box sx={{mb: 1}}>
								<Typography level='title-md'>画像投稿</Typography>
							</Box>
							<Stack spacing={2} sx={{my: 1}}>
								{files.map((elem, i) => (
									elem.progress < 100 ? 
										<div key={i}>
											<Progress variant='determinate' value={elem.progress} />
											<Typography variant='subtitle2'>{elem.progress + '%'}</Typography>
										</div>
										:
										<Typography key={i} variant='subtitle2'>{elem.name}</Typography>
								))}
								{files.length === 0 ?
									<Dropzone onDrop={acceptedFiles => dropFiles(acceptedFiles)}
										maxFiles={1} maxSize={500000000} minSize={1} >
										{({getRootProps, getInputProps, fileRejections}) => (
											<React.Fragment>
												<div {...getRootProps()} style={{
													padding: 20, border: '1px dashed #ccc',
													textAlign:'center', cursor: 'pointer',
													color: '#ccc'
												}}>
													<input {...getInputProps()} />
													<UploadIcon style={{fontSize: 50}} />
													<Typography variant='h5'>添付データ</Typography>
												</div>
												{fileRejections.filter((e, i) => i ===0).map(({file, errors}) => ( 
													<p>{errors.filter((e, i) => i === 0).map(e => e.message)}</p>
												))}
											</React.Fragment>
										)}
									</Dropzone>
									:
									<Input
										placeholder='タイトルを入力してください'
										value={imageTitle}
										onChange={(e) => setImageTitle(e.target.value)}
										sx={{mt: 1.5}}
									/>
								}
							</Stack>
							<CardOverflow sx={{borderTop: '1px solid', borderColor: 'divider'}}>
								<CardActions sx={{alignSelf: 'flex-end', pt: 2}}>
									<Button size='sm' variant='outlined' onClick={() => {setImageTitle(''); setFiles([]);}}>Cancel</Button>
									<Button size='sm' variant='solid' onClick={handleImageClick}>Save</Button>
								</CardActions>
							</CardOverflow>
						</FormControl>
					 </Card>
				</TabPanel>
				{/* 画像一覧 */}
				<TabPanel value={1}>
					<Sheet sx={{p: 1, overflow: 'auto'}}>
						<TableContainer sx={{height: '85vh'}}>
							<Table stickyHeader hoverRow>
								<thead>
									<tr>
										<th>画像投稿</th>
										<th>投稿生成</th>
										<th>インスタ投稿</th>
										<th style={{width: '50px'}}></th>
									</tr>
							 	</thead>
								<tbody>
									{images.map((row, index) => (
										<tr key={index}>
											<td align='center'>
												{row.uploadDate}<br />
												<StorageImage path={'public/thumbnail/' + row.imageFileNm} /><br />
												{row.imageTitle}
											</td>
											<td>{row.generatePost && <>
												{row.generatePost.generatePostDate}<br />
												{row.generatePost.generatePostContents}
											</>}</td>
											<td>{row.generatePost && row.generatePost.postInstagram && <>
												{row.generatePost.postInstagram.postInstagramDate}<br /> 
												{row.generatePost.postInstagram.postInstagramContents}
											</>}</td>
											<td>
												{row.generatePost &&
													<>
														<IconButton onClick={() => {setSelectedRow(row); setOpen(true);}}>
															<EditIcon />
														</IconButton>
														{!row.generatePost.postInstagram &&
															<IconButton onClick={event => deleteRow(event, row)}>
																<DeleteIcon />
															</IconButton>
														}
													</>
												}
												{row.generatePost && row.generatePost.postInstagram &&
													<Switch
														defaultChecked={row.generatePost.postInstagram.exampleAnswerFlg === 'ON'}
														onChange={event => checkExampleAnswer(event, row)}/>
												}
											</td>
										</tr>
									))}
								</tbody>
							</Table>
						</TableContainer>
					</Sheet>
					<BasicModalDialog {...{open, setOpen, selectedRow, getImageList}} />
				</TabPanel>
				{/* システム設定 */}
				<TabPanel value={2}>
					<Card>
						<FormControl>
							<Stack spacing={2} sx={{my: 1}}>
								<Textarea
									placeholder='役割を指定してください'
									value={systemSettingContents}
									onChange={event => setSystemSettingContents(event.target.value)}
									minRows={4} sx={{mt: 1.5}}
								/>
								<FormHelperText sx={{mt: 0.75, fontSize: 'xs'}}>
								</FormHelperText>
							</Stack>
							<CardOverflow sx={{borderTop: '1px solid', borderColor: 'divider'}}>
								<CardActions sx={{alignSelf: 'flex-end', pt: 2}}>
									<Button size='sm' variant='outlined' onClick={() => systemSettingContents('')}>Clear</Button>
									<Button size='sm' variant='solid' onClick={handleSystemClick}>Save</Button>
								</CardActions>
							</CardOverflow>
						</FormControl>
					</Card>
				</TabPanel>
			</Tabs>
		</>
	);
}