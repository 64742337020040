import {atom} from 'recoil';

/**
 * Recoil（状態管理ライブラリ）の定義
 * @author j.kusaka
 */

// 進捗状況表示フラグ
export const isProgress = atom({key: 'isProgress', default: false});

// ユーザ名（owner）
export const username = atom({key: 'username', default: ''});
// ユーザグループ
export const usergroup = atom({key: 'usergroup', default: ''});
// 言語
export const lang = atom({key: 'lang', default: 'ja'});