import * as React from 'react';
import {useRecoilValue} from 'recoil';
import Button from '@mui/joy/Button';
import FormControl from '@mui/joy/FormControl';
import Textarea from '@mui/joy/Textarea';
import Modal from '@mui/joy/Modal';
import ModalClose from '@mui/joy/ModalClose';
import ModalDialog from '@mui/joy/ModalDialog';
import DialogTitle from '@mui/joy/DialogTitle';
import DialogContent from '@mui/joy/DialogContent';
import Stack from '@mui/joy/Stack';
import * as recoil from 'components/recoil';

// Amplify API
import {generateClient} from 'aws-amplify/api';
import {post} from 'graphql/queries';
import {createPostInstagramInfo, updatePostInstagramInfo, updateGeneratePostInfo} from 'graphql/mutations';
const client = generateClient();

export default function BasicModalDialog({open, setOpen, selectedRow, getImageList}) {
	const usergroup = useRecoilValue(recoil.usergroup);
	const [postInstagram, setPostInstagram] = React.useState({postInstagramContents: ''});
	// 初期設定
	React.useEffect(() => {
		const init = async () => {
			if(selectedRow.generatePost) {
				console.log(selectedRow.generatePost);
				if(selectedRow.generatePost.postInstagram) {
					setPostInstagram(selectedRow.generatePost.postInstagram);
				} else {
					setPostInstagram({postInstagramContents: selectedRow.generatePost.generatePostContents});
				}
			}
		};
		init();
	}, [selectedRow]);

	// 一時保存ボタン押下時
	const clickSave = async event => {
		event.preventDefault();
		try {
			updateDynamoDB();
			getImageList();
			setOpen(false);
		} catch(err) {
			console.log(err);
		}
	};
	
	// 投稿ボタン押下
	const clickPost = async event => {
		event.preventDefault();
		try {
			updateDynamoDB();
			if(postInstagram.id) {
				// インスタ投稿
				client.graphql({
					query: post,
					variables: {postInstagramId: postInstagram.id}
				});
			}
			getImageList();
			setOpen(false);
		} catch(err) {
			console.log(err);
		}
	};
	
	// DynamoDB 更新
	const updateDynamoDB = async () => {
		if(postInstagram.id) {
			// インスタ投稿情報 更新
			await client.graphql({
				query: updatePostInstagramInfo,
				variables: {input: {
					id: postInstagram.id,
					postInstagramDate: new Date().toISOString(),
					postInstagramContents: postInstagram.postInstagramContents,
				}}
			});
		} else {
			// インスタ投稿情報 登録
			const result = await client.graphql({
				query: createPostInstagramInfo,
				variables: {input: {
					postInstagramDate: new Date().toISOString(),
					postInstagramContents: postInstagram.postInstagramContents,
					exampleAnswerFlg: 'ON',
					generatePostId: selectedRow.generatePost.id,
					ownerCompanyId: usergroup
				}}
			});
			const postInstagramId = result.data.createPostInstagramInfo.id;
			// 投稿生成情報 更新
			client.graphql({
				query: updateGeneratePostInfo,
				variables: {input: {
					id: selectedRow.generatePost.id,
					postInstagramId: postInstagramId
				}}
			});
			setPostInstagram(prev => ({...prev, id: postInstagramId}));
		}
	}
	
	// 画面描画
	return (
		<Modal open={open} onClose={() => setOpen(false)}>
			<ModalDialog sx={{width: '50%'}}>
				<ModalClose />
				<DialogTitle>インスタ投稿の登録</DialogTitle>
				<DialogContent>生成された内容を参考にインスタ投稿記事を作成してください</DialogContent>
				<Stack spacing={2}>
					<FormControl>
						<Textarea
							value={postInstagram.postInstagramContents}
							onChange={event => setPostInstagram(prev => ({...prev, postInstagramContents: event.target.value}))}
							minRows={4} sx={{mt: 1.5}}
						/>
					</FormControl>
					{!postInstagram.postInstagramMedia &&
						<>
							<Button variant='text' onClick={event => clickSave(event)}>一時保存</Button>
							<Button variant='solid' onClick={event => clickPost(event)}>投稿</Button>
						</>
					}
				</Stack>
			</ModalDialog>
		</Modal>
	);
}