export const listImageInfos = /* GraphQL */ `
  query ListImageInfos(
    $id: ID
    $filter: ModelImageInfoFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listImageInfos(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        uploadDate
        imageTitle
        imageFileNm
        generatePostId
        generatePost {
          id
          generatePostDate
          generatePostContents
          autoPostFlg
          systemSettingContents
          systemSettingId
          postInstagramId
          postInstagram {
            id
            postInstagramDate
            postInstagramContents
            exampleAnswerFlg
            generatePostId
            ownerCompanyId
            createdAt
            updatedAt
            owner
          }
          ownerCompanyId
          createdAt
          updatedAt
          owner
        }
        ownerCompanyId
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;

export const listChatInfos = /* GraphQL */ `
  query ListChatInfos(
    $id: ID
    $filter: ModelChatInfoFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listChatInfos(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        chatDate
        chatQuestion
        chatAnswer
        ragId
        rag {
          id
          ragContents
          datasourceFileNm
          reflectFlg
          chatId
          ownerCompanyId
          createdAt
          updatedAt
          owner
          __typename
        }
        ownerCompanyId
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
