/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const generate = /* GraphQL */ `
  query Generate($imageId: ID!) {
    generate(imageId: $imageId) {
      generatePostId
      __typename
    }
  }
`;
export const post = /* GraphQL */ `
  query Post($postInstagramId: ID!) {
    post(postInstagramId: $postInstagramId) {
      postInstagramId
      __typename
    }
  }
`;
export const regist = /* GraphQL */ `
  query Regist($ragId: ID) {
    regist(ragId: $ragId) {
      ragId
      __typename
    }
  }
`;
export const getSystemSettingInfo = /* GraphQL */ `
  query GetSystemSettingInfo($id: ID!) {
    getSystemSettingInfo(id: $id) {
      id
      systemSettingContents
      autoPostSettingFlg
      ownerCompanyId
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const listSystemSettingInfos = /* GraphQL */ `
  query ListSystemSettingInfos(
    $id: ID
    $filter: ModelSystemSettingInfoFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listSystemSettingInfos(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        systemSettingContents
        autoPostSettingFlg
        ownerCompanyId
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getImageInfo = /* GraphQL */ `
  query GetImageInfo($id: ID!) {
    getImageInfo(id: $id) {
      id
      uploadDate
      imageTitle
      imageFileNm
      generatePostId
      generatePost {
        id
        generatePostDate
        generatePostContents
        autoPostFlg
        systemSettingContents
        systemSettingId
        postInstagramId
        ownerCompanyId
        createdAt
        updatedAt
        owner
        __typename
      }
      ownerCompanyId
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const listImageInfos = /* GraphQL */ `
  query ListImageInfos(
    $id: ID
    $filter: ModelImageInfoFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listImageInfos(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        uploadDate
        imageTitle
        imageFileNm
        generatePostId
        ownerCompanyId
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const imageInfosByGeneratePostId = /* GraphQL */ `
  query ImageInfosByGeneratePostId(
    $generatePostId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelImageInfoFilterInput
    $limit: Int
    $nextToken: String
  ) {
    imageInfosByGeneratePostId(
      generatePostId: $generatePostId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        uploadDate
        imageTitle
        imageFileNm
        generatePostId
        ownerCompanyId
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getGeneratePostInfo = /* GraphQL */ `
  query GetGeneratePostInfo($id: ID!) {
    getGeneratePostInfo(id: $id) {
      id
      generatePostDate
      generatePostContents
      autoPostFlg
      systemSettingContents
      systemSettingId
      systemSetting {
        id
        systemSettingContents
        autoPostSettingFlg
        ownerCompanyId
        createdAt
        updatedAt
        owner
        __typename
      }
      postInstagramId
      postInstagram {
        id
        postInstagramDate
        postInstagramContents
        exampleAnswerFlg
        postInstagramMedia
        generatePostId
        ownerCompanyId
        createdAt
        updatedAt
        owner
        __typename
      }
      imageInfo {
        nextToken
        __typename
      }
      ownerCompanyId
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const listGeneratePostInfos = /* GraphQL */ `
  query ListGeneratePostInfos(
    $id: ID
    $filter: ModelGeneratePostInfoFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listGeneratePostInfos(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        generatePostDate
        generatePostContents
        autoPostFlg
        systemSettingContents
        systemSettingId
        postInstagramId
        ownerCompanyId
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getPostInstagramInfo = /* GraphQL */ `
  query GetPostInstagramInfo($id: ID!) {
    getPostInstagramInfo(id: $id) {
      id
      postInstagramDate
      postInstagramContents
      exampleAnswerFlg
      postInstagramMedia
      generatePostId
      generatePost {
        id
        generatePostDate
        generatePostContents
        autoPostFlg
        systemSettingContents
        systemSettingId
        postInstagramId
        ownerCompanyId
        createdAt
        updatedAt
        owner
        __typename
      }
      ownerCompanyId
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const listPostInstagramInfos = /* GraphQL */ `
  query ListPostInstagramInfos(
    $id: ID
    $filter: ModelPostInstagramInfoFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listPostInstagramInfos(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        postInstagramDate
        postInstagramContents
        exampleAnswerFlg
        postInstagramMedia
        generatePostId
        ownerCompanyId
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const postInstagramInfosByGeneratePostId = /* GraphQL */ `
  query PostInstagramInfosByGeneratePostId(
    $generatePostId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelPostInstagramInfoFilterInput
    $limit: Int
    $nextToken: String
  ) {
    postInstagramInfosByGeneratePostId(
      generatePostId: $generatePostId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        postInstagramDate
        postInstagramContents
        exampleAnswerFlg
        postInstagramMedia
        generatePostId
        ownerCompanyId
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getChatInfo = /* GraphQL */ `
  query GetChatInfo($id: ID!) {
    getChatInfo(id: $id) {
      id
      chatDate
      chatQuestion
      chatAnswer
      ragId
      rag {
        id
        ragContents
        datasourceFileNm
        reflectFlg
        chatId
        ownerCompanyId
        createdAt
        updatedAt
        owner
        __typename
      }
      ownerCompanyId
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const listChatInfos = /* GraphQL */ `
  query ListChatInfos(
    $id: ID
    $filter: ModelChatInfoFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listChatInfos(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        chatDate
        chatQuestion
        chatAnswer
        ragId
        ownerCompanyId
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getRagInfo = /* GraphQL */ `
  query GetRagInfo($id: ID!) {
    getRagInfo(id: $id) {
      id
      ragContents
      datasourceFileNm
      reflectFlg
      chatId
      chat {
        id
        chatDate
        chatQuestion
        chatAnswer
        ragId
        ownerCompanyId
        createdAt
        updatedAt
        owner
        __typename
      }
      ownerCompanyId
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const listRagInfos = /* GraphQL */ `
  query ListRagInfos(
    $id: ID
    $filter: ModelRagInfoFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listRagInfos(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        ragContents
        datasourceFileNm
        reflectFlg
        chatId
        ownerCompanyId
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const ragInfosByChatId = /* GraphQL */ `
  query RagInfosByChatId(
    $chatId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelRagInfoFilterInput
    $limit: Int
    $nextToken: String
  ) {
    ragInfosByChatId(
      chatId: $chatId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        ragContents
        datasourceFileNm
        reflectFlg
        chatId
        ownerCompanyId
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
