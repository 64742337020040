import {useTranslation} from 'react-i18next';
import Paper from '@mui/material/Paper';
import Alert from '@mui/material/Alert';
// import Layout from '../templates/Layout';

/**
 * NotFound画面
 */

const NotFound = (props) => {
  const {t} = useTranslation();

  // 画面描画
  return (
      <Paper align='center'>
        <Alert severity='error'>{t('指定のページが見つかりません')}</Alert>
      </Paper>
  );
};
export default NotFound;