const dict = {
  ja: {
    // Sign In
    'Sign In': 'ログイン', // Tab header
    'Sign in': 'ログイン', // Button label
    'Sign in to your account': 'ログイン情報を入力してください',
    'Username': 'ユーザ名', // Username label
    'Email': 'メールアドレス', // Username label
    'Password': 'パスワード（8文字以上）', // Password label
    'Forgot your password?': 'パスワードを忘れた？',
    
    'Signing in': 'ログイン中...',
    'User does not exist.': 'ユーザが存在しません',
    'Incorrect username or password.': 'メールアドレスまたはパスワードが違います',

    // Reset Password
    'Reset your password': 'パスワードのリセット',
    'Enter your email': 'メールアドレス',
    'Send code': 'パスワードのリセット',
    'Back to Sign In': 'ログインに戻る',
    
    // Change Password
    'Change Password': 'パスワードの変更',
    
    'Code': '認証コード',
    'New password': '新しいパスワード',
    'Confirm Password': '新しいパスワード（確認用）',
    'Submit': '設定',
    'Resend Code': '認証コードの再送',
    'Your passwords must match': 'パスワードが異なります'
  }
};

export default dict;