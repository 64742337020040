import '@aws-amplify/ui-react/styles.css';
// import {useState} from 'react';
import {useRecoilValue, useSetRecoilState} from 'recoil';
import {BrowserRouter, Routes, Route} from 'react-router-dom';
import {Amplify} from 'aws-amplify';
import {fetchAuthSession} from 'aws-amplify/auth';
import {I18n} from 'aws-amplify/utils';
import {Authenticator} from '@aws-amplify/ui-react';
import config from './aws-exports';
import authdict from './locales/auth-ja';

import Home from './endpoints/Home';
import Chat from './endpoints/Chat';
import NotFound from './endpoints/NotFound';
import * as recoil from './components/recoil';

// 多言語対応
import i18n from 'i18next';
import {initReactI18next} from 'react-i18next';
import localeEn from './locales/en.json';
i18n.use(initReactI18next).init({
	resources: {en: {translation: {...localeEn}}},
	fallbackLng: false,			 // フォールバックしない＝keyをそのまま表示
	returnEmptyString: false, // 空文字での定義を許可
});

// Amplify 定義
Amplify.configure(config);
// Amplify 多言語対応
I18n.putVocabularies(authdict);

export default function App() {
	const lang = useRecoilValue(recoil.lang);
	const setUsername = useSetRecoilState(recoil.username);
	const setUsergroup = useSetRecoilState(recoil.usergroup);

	I18n.setLanguage(lang);
	i18n.changeLanguage(lang);
	
	const setUserSession = async (user) => {
		try {
			const session = await fetchAuthSession();
			setUsername(user.username);
			setUsergroup(session.tokens.idToken.payload['cognito:groups'][0]);
		} catch(err) {
			console.log(err);
		}
	};

	// 画面描画
	return (
		<Authenticator loginMechanisms={['email']} hideSignUp={true} variation='modal'>
			{({user, signOut}) => {
				setUserSession(user);
				return (
					<BrowserRouter>
						<Routes>
							<Route path='/' element={<Home />} />
							<Route path='/chat' element={<Chat />} />
							<Route path='*' element={<NotFound />} />
						</Routes>
					</BrowserRouter>
				);
			}}
		</Authenticator>
	);
}